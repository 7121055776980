import Helmet from "react-helmet"
import { useNavigate } from "react-router-dom"

import PageContainer from "@podcastsoundboard/ps-lib/components/PageContainer"

import PublicIcon from "@mui/icons-material/Public"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"

import { Chip, Divider, useMediaQuery } from "@mui/material"
import DeviceChips from "@podcastsoundboard/ps-lib/components/DeviceChips"
import AppBar from "../../../../components/AppBar"
import Footer from "../../../../components/Footer"
import FullPageContentContainer from "../../../../components/FullPageContentContainer"
import { useAppSelector } from "../../../../redux"
import HomeContent from "../../../Home/components/HomeContent"

import {
  AndroidChip,
  IOSChip,
} from "@podcastsoundboard/ps-lib/components/DeviceChips/DeviceChips"

export default function Mobile() {
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)
  const navigate = useNavigate()

  const TitlePaper = (
    <Paper sx={{ p: 4, maxWidth: "800px" }}>
      <Stack
        direction="column"
        spacing={3}
        justifyContent="center"
        alignItems="flex-start"
      >
        <Typography
          variant="body1"
          align="left"
          color="text.secondary"
          fontWeight={800}
          sx={{ pt: 2, mb: 1 }}
        >
          Mobile App
        </Typography>
        <Typography
          component="h2"
          variant="h3"
          align="left"
          color="text.primary"
          sx={{ mt: 0, pb: 2 }}
        >
          Podcast Soundboard for iOS & Android
        </Typography>
      </Stack>

      <Typography
        variant="body1"
        align="left"
        color="text.primary"
        sx={{ pb: 4, mt: 4 }}
        fontSize={"1.2rem"}
      >
        Podcast Soundboard for Mobile is the perfect soundbite and audio
        playback tool for those who need something portable or offline.
      </Typography>

      <Typography
        variant="body1"
        align="left"
        color="text.secondary"
        sx={{ mb: 4 }}
      >
        Purchase the mobile app via the Apple App Store or Google Play Store.
      </Typography>

      <Stack direction="row" gap="0.5rem" flexWrap="wrap" sx={{ mt: 4 }}>
        <IOSChip
          handleOpenUrl={(url) => window.open(url, "_blank")}
          backgroundColor="white"
          overrideColor="black"
          width="auto"
          size="small"
        />
        <AndroidChip
          handleOpenUrl={(url) => window.open(url, "_blank")}
          width="auto"
          size="small"
        />

        <Chip
          size="medium"
          clickable
          color="primary"
          onClick={() =>
            currentUserProfile
              ? navigate("/app/account/subscription")
              : navigate("/app/signup")
          }
          label="Web"
          icon={<PublicIcon />}
        />

        <Chip
          size="medium"
          clickable
          onClick={() => navigate("/app/platforms/mobile/changelog")}
          label="Changelog"
        />
      </Stack>
    </Paper>
  )

  const Image = (
    <img
      className="mobile-device"
      src={
        darkModeEnabled
          ? "/img/devices/mobile-dark.png"
          : "/img/devices/mobile-light.png"
      }
      alt={darkModeEnabled ? "Dark mode on mobile" : "Light mode on mobile"}
    />
  )

  const isSmallScreen = useMediaQuery("(max-width: 640px)")

  return (
    <PageContainer darkModeEnabled={darkModeEnabled}>
      <AppBar invert />

      <Helmet titleTemplate="%s">
        <title>Podcast Soundboard for iOS and Android</title>
        <meta
          name="description"
          content="Podcast Soundboard is the best mobile soundboard application. Featuring many customization and control options. Available on all platforms."
        />
      </Helmet>

      <Box className="hero hero-bg-1" sx={{ pb: 5 }}>
        <Toolbar />

        <FullPageContentContainer minHeight="auto" maxWidth="xl">
          <DeviceChips
            handleOpenUrl={(url) => window.open(url, "_blank")}
            navigate={navigate}
          />

          <Stack
            direction="row"
            gap="1rem"
            alignItems="center"
            flexWrap={isSmallScreen ? "wrap" : "nowrap"}
            justifyContent="center"
          >
            {TitlePaper}
            {Image}
          </Stack>
        </FullPageContentContainer>
      </Box>

      <HomeContent />

      <Divider />
      <Footer />
    </PageContainer>
  )
}
