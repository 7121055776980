import * as React from 'react'

import { Slider, Stack, Tooltip } from '@mui/material'
import Typography from '@mui/material/Typography'
import { SliderValueLabelProps } from '@mui/material'
import tinycolor from 'tinycolor2'

import durationToString from '../../helpers/audio/durationToString'
import { PRIMARY_PURPLE } from '../../colors'

interface ProgressBarProps {
  darkModeEnabled: boolean
  color: string
  duration: number | null
  progressDecimal: number
  hasTrack?: boolean
  fileNotFound?: boolean
  onSeek: (time: number) => void
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  darkModeEnabled,
  color,
  duration,
  progressDecimal,
  hasTrack,
  fileNotFound,
  onSeek,
}) => {
  const currentTime = progressDecimal * (duration ?? 0)

  // const [temporarySliderValue, setTemporarySliderValue] = React.useState<
  //   number | null
  // >(currentTime)

  const durationValid = Boolean(
    !Number.isNaN(Number(duration)) &&
      duration &&
      Number.isFinite(Number(duration)),
  )

  const handleChange = (_event: Event, newValue: number | number[]) => {
    onSeek(newValue as number)
  }

  // const handleChangeCommitted = (
  //   _event: Event | React.SyntheticEvent,
  //   newValue: number | number[],
  // ) => {
  //   const time = newValue as number

  //   onSeek(time)
  //   setTemporarySliderValue(null)
  // }

  if (!durationValid || duration === null) {
    return null
  }

  const backgroundColor = tinycolor(color || PRIMARY_PURPLE)
    .setAlpha(0.6)
    .toRgbString()

  const textColor = tinycolor
    .mostReadable(
      darkModeEnabled
        ? tinycolor(color).darken(30)
        : tinycolor(color).lighten(30),
      ['#333', '#fff'],
    )
    .toHexString()

  return (
    <Stack
      direction="row"
      spacing={1.5}
      alignItems="center"
      justifyContent="space-between"
      sx={{
        width: '100%',
        mx: 'auto',
        px: 0.5,
        py: 0,
        zIndex: 1,
      }}
      style={{ backgroundColor: backgroundColor }}
    >
      <Typography
        variant="caption"
        sx={{
          color: textColor,
        }}
        fontSize="0.85rem"
      >
        {durationToString(currentTime)}
      </Typography>

      <Slider
        size="small"
        value={currentTime}
        onChange={handleChange}
        // onChangeCommitted={handleChangeCommitted}
        sx={{
          color: color,
          '& .MuiSlider-markLabel': {
            color: darkModeEnabled ? 'white' : 'black',
          },

          transition: 'none',
          '& .MuiSlider-thumb': {
            transition: 'none',
          },
          '& .MuiSlider-track': {
            transition: 'none',
          },
          '& .MuiSlider-rail': {
            transition: 'none',
          },
          '@media (pointer: coarse)': {
            padding: '10px 0px', // Override for touch devices
          },
          // p: 0,
        }}
        min={0}
        max={duration}
        step={0.1}
        disabled={!hasTrack || fileNotFound}
        disableSwap
      />

      <Typography
        variant="caption"
        sx={{
          color: textColor,
        }}
        fontSize="0.85rem"
      >
        {durationToString(duration)}
      </Typography>
    </Stack>
  )
}

export default ProgressBar
