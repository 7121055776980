export default function durationToString(duration = 0) {
  let newDuration = duration
  if (newDuration < 0.005) newDuration = 0
  const minutes = Math.floor(newDuration / 60)
  const seconds = newDuration % 60

  if (minutes === 0) {
    return seconds.toFixed(1)
  }

  // Pad seconds with leading zero if needed
  const paddedSeconds =
    seconds < 10 ? `0${seconds.toFixed(0)}` : seconds.toFixed(0)
  return `${minutes}:${paddedSeconds}`
}
